.teamMembers {
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px;
    z-index: 100;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    $teammember : #{&};

    .userNameBlock {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .open-request-btn {
        display: none;
        width: 100%;
    }

    &.is-fixed {


        .close-icon {
            display: none;
        }

        #{$teammember}__label {
            pointer-events: none;
            cursor: default;
        }
    }

    &.is-new-members {
        .open-request-btn {
            display: block;
        }

        #{$teammember}__notifications {
            opacity: 1;
            visibility: visible;

            &.is-hidden {
                opacity: 0;
                visibility: hidden;
            }

            &--inner {
                transform: translateX(0);

                &.is-hidden {
                    transform: translateX(50%);
                }

            }
        }

        #{$teammember}__label:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            background-color: $color-primary;
            border-radius: 7px;
            box-shadow: 0 0 0 0 rgba($color-primary, .5);
            animation: notificationPulse infinite 1s linear;


            @keyframes notificationPulse {
                0% {
                    box-shadow: 0 0 0 0 rgba($color-primary, .5);
                }

                50% {
                    box-shadow: 0 0 0 4px rgba($color-primary, .5);
                }

                100% {
                    box-shadow: 0 0 0 4px rgba($color-primary, 0);
                }

            }
        }

        &.is-fixed {
            #{$teammember}__label:before {
                right: auto;
                left: 0;
            }

            .open-request-btn {
                display: none;
            }
        }
    }

    &.is-toggleable {
        margin: 15px 20px;
        z-index: 10000;

        #{$teammember}__label {
            width: 36px;
            height: 36px;
            position: relative;
            color: $color-primary;
            border-radius: 18px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                path {
                    fill: $color-primary;
                }
            }

            &:hover {
                background-color: $color-primary;
                color: #ffffff;

                svg {
                    path {
                        fill: #ffffff;
                    }
                }
            }

            span {
                display: none;
            }

            .close-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                display: none;
            }
        }

        #{$teammember}__wrap {
            border-radius: 5px;
            background: #00000042;
            backdrop-filter: blur(4px);
            padding: 10px;
            display: none;
        }

        &.is-expanded {
            #{$teammember}__label {
                svg {
                    opacity: 0;
                }

                .close-icon {
                    display: block;
                }
            }

            #{$teammember}__wrap {
                display: block;
                animation: membersBox ease-in-out forwards .3s;

                @keyframes membersBox {
                    from {
                        opacity: 0;
                        max-height: 0;
                        transform: translateY(-10px);
                    }

                    to {
                        max-height: 420px;
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }

    &__wrap {
        position: relative;
        top: 100%;
        right: 0;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            outline: 1px solid transparent;
        }
    }

    &__list {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &__label {
        background: no-repeat;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        padding: 0;

        &:hover {
            background: none;
        }
    }

    &__item {
        min-width: 160px;
        max-width: 240px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 0 10px 10px;
        gap: 10px;
        font-weight: 700;
        font-size: 14px;
        border-radius: 5px;

        &.is-teamlead {
            background: #ffffff;
            color: #000000;
            padding-right: 10px;
            order: -1;

            #{$teammember}__action {
                display: none;
            }

            .leaderStar {
                display: block;
            }
        }

        &.active {
            z-index: 2;

            #{$teammember}__action,
            #{$teammember}__action--menu {
                opacity: 1;
                visibility: visible;
                pointer-events: inherit;
            }
        }

        &:not(.is-teamlead) {
            color: #ffffff;

            &:hover {
                background-color: rgba(#ffffff, .15);

                #{$teammember}__action {
                    opacity: 1;
                    pointer-events: inherit;
                }
            }

            &.inactive {
                opacity: .5;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &.is-ready {
            #{$teammember}__avatar {
                filter: none;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 8px;
                background: $color-success;
                border-radius: 50%;
            }
        }
    }

    &__newmember {
        &>h5 {
            margin: 0;
            padding: 15px;
            color: #666666;
        }

        #{$teammember}__item {
            max-width: 100%;
            color: #000000;
            padding: 10px;
            border-top: solid 1px #eeeeee;
        }
    }

    .leaderStar {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0;
        width: 14px;
        height: 14px;
        background-color: $color-primary;
        background-image: url(../../images/star.svg);
        background-size: 8px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 7px;
        border-radius: 7px;
        box-shadow: 0 0 0 3px rgba($color-primary, .5);
        display: none;
        z-index: 3;
    }

    &__avatar {
        width: 32px;
        height: 32px;
        display: flex;
        flex: 0 0 32px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        filter: grayscale(100%);
    }

    &__action {
        position: relative;
        opacity: 0;
        pointer-events: none;
        display: none;

        .leadView & {
            display: block;
        }

        &--menu {
            position: absolute;
            top: 100%;
            right: 0;
            display: flex;
            flex-direction: column;
            padding: 8px;
            background: #ffffff;
            gap: 5px;
            border-radius: 5px;
            margin-right: -10px;
            opacity: 0;
            visibility: hidden;

            button {
                padding: 5px;
                font-size: 12px;

                &:first-child {
                    background: no-repeat;
                    color: #666666;

                    &:hover {
                        color: #000000;
                        background-color: #eeeeee;
                    }
                }
            }
        }

        &--toggle {
            font-size: 0;
            padding: 0;
            background: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 10px;

            &:hover {
                background-color: transparent;
            }

            span,
            span:after,
            span:before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #dddddd;
            }

            span {
                margin: 10px 0;
                position: relative;

                &:after,
                &:before {
                    position: absolute;
                    left: 50%;
                }

                &:after {
                    bottom: 100%;
                    transform: translate(-50%, -3px);
                }

                &:before {
                    top: 100%;
                    transform: translate(-50%, 3px);
                }
            }
        }
    }

    &__detail {
        flex: 1;
        overflow: hidden;

        .leaderLabel {
            font-size: 80%;
            display: block;
            color: $color-primary
        }
    }

    &__notifications {
        position: absolute;
        width: 360px;
        top: 100%;
        right: 0;
        font-size: 14px;
        opacity: 0;
        visibility: hidden;
        transition: $transition--primary;
        overflow: hidden;



        &--inner {
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0 5px 15px -5px;
            position: relative;
            transform: translateX(50%);
            transition: $transition--primary;
        }

        &--content {
            display: flex;
            flex-direction: column;
            gap: 0;
            padding: 10px;

            h5 {
                margin: 0 0 5px 0;
                font-size: 16px;
            }

            p {
                margin: 0;
            }
        }

        &--actions {
            display: flex;
            gap: 10px;
            padding-top: 10px;
        }
    }
}

span#assign-leader-player-name {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

#assign-leader-text {
    .userWrap {
        display: inline-flex;
        align-items: center;
    }
}

#newMembers-popup {
    z-index: 100000;
}
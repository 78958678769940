.lobby {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;

    &__inner {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: rgba($color-dark, .8);
        align-items: center;
        justify-content: center;
        padding: 60px 20px;
    }

    &__content {
        max-height: 100%;
        overflow: auto;
    }

    .teamMembers__notifications {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        opacity: 1;
        visibility: visible;
        width: 100%;
        max-width: 480px;

        &.is-hidden {
            opacity: 0;
            visibility: hidden;
        }

        .teamMembers__notifications--inner {
            transform: none;

            .teamMembers__notifications--content {
                padding: 20px;
                gap: 10px;
                text-align: center;
            }

            .teamMembers__notifications--actions {
                justify-content: center;
            }
        }
    }
}

#waiting-popup {

    &.popup {
        $local-color: mix($color-primary, $color-dark, 60);
        background-color: mix($local-color, transparent, 90);

        .popup-box {
            background: none;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;

            &>h3 {
                margin: 30px 0 0 0;
            }

        }
    }

    .waiting-spinner {
        animation: spinningAnim infinite linear 1.5s;
        width: 70px;
        height: 70px;
        border-style: solid;
        border-width: 6px;
        border-color: #ffffff #ffffff #ffffff rgba(#ffffff, .5);
        border-radius: 50%;

        @keyframes spinningAnim {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
}